import { batch } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, uniqBy } from 'lodash';

import { RootState, AppThunk } from '../store';
import { IRubricItem } from '@http/models/rubric-item';
import { IChallengeItem } from '@http/models/challenge-item';
import { ChallengeListResponse } from '@http/api/v1/challenge/challenge';
import { v1 } from '@api/v1';

interface ILoadChallengeListRequest {
  rubricList?: IRubricItem[];
  loadmore?: boolean;
}
interface IChallengeListState {
  loading: boolean;
  items: IChallengeItem[];
  scrollPosition: number;
  total: number;
  page: number;
  take: number;
  error?: string;
}

const initialState: IChallengeListState = {
  loading: true,
  items: [],
  scrollPosition: 0,
  total: 0,
  page: 1,
  take: 25,
};

const slice = createSlice({
  name: 'challengeList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<IChallengeItem[]>) => {
      state.items = action.payload;
    },
    addItems: (state, action: PayloadAction<IChallengeItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'id');
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTake: (state, action: PayloadAction<number>) => {
      state.take = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

const { setLoading, setItems, setPage, setTake, setError, setTotal, addItems, setScrollPosition } =
  slice.actions;

const challengeList = {
  addItems,
  setTotal,
  setLoading,
  setItems,
  setScrollPosition,
  setPage,
  setTake,
  setError,
  selectLoading: (state: RootState) => state.challengeList.loading,
  selectItems: (state: RootState) => state.challengeList.items,
  selectScrollPosition: (state: RootState) => state.challengeList.scrollPosition,
  selectError: (state: RootState) => state.challengeList.error,
  selectPage: (state: RootState) => state.challengeList.page,
  selectTotal: (state: RootState) => state.challengeList.total,
  selectTake: (state: RootState) => state.challengeList.take,
  loadData:
    (request?: ILoadChallengeListRequest): AppThunk =>
    async (dispatch, getState) => {
      const { rubricList, loadmore = false } = request || {};
      const state = getState();

      try {
        dispatch(setError());
        const rubricsId = isEmpty(rubricList) ? [] : rubricList?.map((i: IRubricItem) => i.id);

        const response: ChallengeListResponse = await v1.challenge.published.get({
          skills: { fetch: true },
          rubrics: { id: rubricsId, fetch: true },
          attributes: { fetch: true },
          page: loadmore ? state.challengeList.page + 1 : 1,
          take: 20,
        });

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }

        batch(() => {
          dispatch(loadmore ? addItems(response.items) : setItems(response.items));
          dispatch(setPage(loadmore ? state.challengeList.page + 1 : 1));
          dispatch(setTotal(response.total));
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const challengeListReducer = slice.reducer;
export default challengeList;

import { apiClients } from '@api/index';
import { ApiResponse } from '@http/types';
import { EUserPermissions } from '@http/enums';

export interface IAccessRights {
  permissions: EUserPermissions[];
}

interface IAccessRightsResponse extends IAccessRights, ApiResponse {}

export const accessRights = {
  get: async (): Promise<IAccessRightsResponse> => {
    return await apiClients.default.get<IAccessRightsResponse>(`v1/user/access-rights`);
  },
};
